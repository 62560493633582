<template>
  <article
    class="bonus-game-card"
    :class="{ mobile, 'not-available': notAvailableInRegion }"
  >
    <div v-if="labels.length" class="labels">
      <StLabel
        v-for="label in labels"
        :key="label.name"
        :label="label.name"
        :bg-color="label.color"
      />
    </div>
    <figure class="img-wrapper">
      <div class="img">
        <div v-if="notAvailableInRegion" class="img-content">
          {{ t('casino.notAvailableInRegion') }}
        </div>
        <div v-if="selected" class="overlay">
          <div class="checked">
            <StIcon name="check" size="16" />
          </div>
        </div>
      </div>
      <figcaption class="info-wrapper">
        <div class="text-wrapper">
          <h3 class="card-header" data-t="game-name">
            {{ game.name }}
          </h3>
          <p class="card-caption" data-t="game-provider">
            {{ game.provider }}
          </p>
        </div>
        <div v-if="showPlayers" class="players-info">
          <span class="players-count">
            <StLiveIndicator :size="16" color="orange" />
            <span>{{ game.playersCount }}</span>
          </span>
          <span class="players">{{ t('gameCard.plays') }}</span>
        </div>
      </figcaption>
    </figure>
  </article>
</template>

<script setup lang="ts">
import useGameCard from '@st/casino/components/GameCard/useGameCard'
import type { Game } from '@st/casino/types'

const { t } = useI18n()
const props = withDefaults(
  defineProps<{
    game: Game
    showPlayerCount?: boolean
    selected?: boolean
    mobile?: boolean
  }>(),
  {
    showPlayerCount: true,
  },
)

const { game, showPlayerCount } = toRefs(props)
const { labels, imagePath, showPlayers, notAvailableInRegion } = useGameCard(
  game,
  showPlayerCount,
)
</script>

<style scoped>
.img {
  cursor: pointer;

  position: relative;
  z-index: 1;

  aspect-ratio: 16/10;
  width: 100%;

  background: v-bind(imagePath);
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-050);

  transition:
    transform 0.15s ease-in-out,
    scale 0.15s ease-in-out;

  .overlay {
    position: absolute;
    z-index: 99999;
    inset: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background: rgb(0 0 0 / 50%);
  }

  .checked {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: var(--spacing-100);

    background-color: var(--icon-tertiary);
    backdrop-filter: blur(20px);
    border-radius: var(--border-radius-full);
  }
}

.bonus-game-card {
  position: relative;
}

.bonus-game-card:hover .img {
  transform: scale(1.1);
}

.bonus-game-card:hover.not-available .img {
  transform: scale(1);
}

.img-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  border-radius: var(--border-radius-050);

  &.not-available {
    pointer-events: none;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      inset: 0;
    }
  }
}

.info-wrapper {
  display: flex;
  gap: var(--spacing-050);
  align-items: flex-start;
  align-self: stretch;

  padding: var(--spacing-100) var(--spacing-100) 0 var(--spacing-100);
}

.text-wrapper {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-start;

  min-width: 0;
}

.card-header {
  overflow: hidden;

  max-width: 100%;
  margin: 0;

  font: var(--desktop-text-md-semibold);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-caption {
  overflow: hidden;

  max-width: 100%;
  margin: 0;

  font: var(--desktop-text-xxs-medium);
  color: var(--text-tertiary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.players-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
  align-items: flex-end;
  justify-content: center;

  width: var(--spacing-600);
  padding-top: var(--spacing-050);
}

.players-count {
  display: flex;
  align-items: center;
  font: var(--desktop-text-xs-medium);
}

.players {
  font: var(--desktop-text-xxs-medium);
  color: var(--text-tertiary);
}

.labels {
  position: absolute;
  z-index: 2;
  top: 10px;
  left: -4px;
  transform: translateX(0);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  align-items: flex-start;

  opacity: 1;
}

.bonus-game-card.mobile {
  .card-header {
    font: var(--mobile-text-semibold);
  }

  .card-caption {
    font: var(--mobile-caption-2-medium);
  }

  .players {
    font: var(--mobile-caption-2-medium);
  }

  .info-wrapper {
    gap: var(--spacing-025);
    padding: var(--spacing-050) var(--spacing-075) 0 var(--spacing-075);
  }
}

.img-content {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: var(--spacing-300);

  font: var(--desktop-text-sm-semibold);
  text-align: center;

  background-color: #000000a3; /* stylelint-disable-line */
}
</style>
